import styled from 'styled-components';
import { media } from 'styles/utils';

export const ButtonContainer = styled.div`
  button {
    margin-bottom: 8px;
  }

  ${media.desktop`
    max-width: 375px;
  `}
`;
