import styled from 'styled-components';

export const SeriesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;
`;

export const SeriesCard = styled.div<SeriesCardProps>`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ isSelected, theme }) => isSelected ? theme.colors.primary : theme.colors.white};
  border-radius: 4px;
  display: grid;
  grid-template-columns: auto 20%;
  padding: 16px 24px 16px 24px;
`;

type SeriesCardProps = {
  isSelected: boolean;
};

export const SeriesContentContainer = styled.div`
  grid-column: 1;
  display: flex;
  flex-direction: column;
  align-content: center;
  cursor: pointer;
`;

export const SeriesAmountContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
