import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { Router, useLocation } from '@reach/router';

import { useSelector, useDispatch, useAuthenticationUser } from 'services/hooks';
import { selectClass } from 'selectors/classDetail';
import { getClass, getClassSeries } from 'ducks/classes/detail';
import { createBooking, getBookings } from 'ducks/booking';
import ArrowLeft from 'vectors/arrowLeft.svg';
import { Text } from 'common/typography';

import {
  BookingMultipleSeries, BookingCTA, BookingDetailHeader, BookingSpots, BookingSuccess,
  BookingSuccessWaitList, BookingDetailCard,
} from './components';
import { PageContainer, ContentContainer, PreviousButton, Content } from './styled';

const BookingDetailPage = ({ id }: BookingDetailPageProps) => {
  const { t } = useTranslation();
  const data = useStaticQuery<GatsbyTypes.SpotLayoutQuery>(graphql`
    query SpotLayout {
      allContentfulTypeSpotLayout {
        edges {
          node {
            id
            spotLayout {
              file {
                url
              }
            }
            node_locale
            zingfitRoomId
          }
        }
      }
    }
  `);

  const dispatch = useDispatch();
  const location = useLocation();
  const { navigate } = useI18next();
  const spotId = location?.state?.spotId || '' as LocationState;

  const [selectedSpot, setSelectedSpot] = React.useState(spotId || '');
  const [selectedSerie, setSelectedSerie] = React.useState('');
  const [selectedSpotMap, setSelectedSpotMap] = React.useState('');
  const classDetail = useSelector(selectClass);
  const { authenticated, determined } = useAuthenticationUser();
  const { language } = useI18next();

  const spotData = data?.allContentfulTypeSpotLayout?.edges.filter((edge) => {
    return edge.node.node_locale === language;
  });

  React.useEffect(() => {
    if (determined) {
      if (id) dispatch(getClass(id));
      if (id && authenticated) {
        dispatch(getBookings());
        dispatch(getClassSeries(id)).then((series) => {
          if (series?.length > 0) setSelectedSerie(series[0].id);
        });
      }
    }
  }, [authenticated, determined]);

  React.useEffect(() => {
    if (classDetail?.roomId) {
      // spotData is array containing objects with a json file and a roomId.
      // the url of the json file needs to be set in the useState, in order to fetch the url in a child component.
      const selectSpotMap = spotData.filter((spotmap) => spotmap.node.zingfitRoomId === classDetail.roomId);
      const layoutUrl = selectSpotMap[0]?.node?.spotLayout?.file?.url;

      setSelectedSpotMap(layoutUrl || '');
    } else {
      setSelectedSpotMap('');
    }
  }, [classDetail]);

  const submitBooking = (isWaitList?: boolean) => {
    if (!id) return;

    dispatch(createBooking({
      classId: id,
      spotId: selectedSpot,
      seriesItemId: selectedSerie,
      isWaitList,
    })).then(() => {
      setSelectedSpot('');
      setSelectedSerie('');
      const route = isWaitList ? 'waitlist-success' : 'success';
      navigate(`/class/${id}/${route}`);
    });
  };

  return (
    <PageContainer>
      <BookingDetailHeader />
      <ContentContainer>
        <Content>
          <PreviousButton onClick={() => navigate('/booking')}>
            <ArrowLeft /> <span>{t('back')}</span>
          </PreviousButton>
          {classDetail && (
            <Router location={location}>
              <BookingCTA
                {... {
                  path: '/',
                  classDetail,
                  submitBooking,
                }}
              />
              <BookingMultipleSeries
                {... {
                  path: 'series',
                  selectedSerie,
                  setSelectedSerie,
                  submitBooking,
                }}
              />
              <BookingSpots
                {... {
                  path: 'spots',
                  id,
                  selectedSpot,
                  setSelectedSpot,
                  submitBooking,
                  selectedSpotMap,
                }}
              />
              <BookingSuccess path="/success" id={id} />
              <BookingSuccessWaitList path="/waitlist-success" />
            </Router>
          )}
        </Content>
        {classDetail && (
          <div>
            <Text $uppercase $weight={700} $margin="0 0 16px">{t('Selected workout')}</Text>
            <BookingDetailCard item={classDetail} />
          </div>
        )}
      </ContentContainer>
    </PageContainer>
  );
};

type BookingDetailPageProps = {
  id?: string;
  path: string;
};

type LocationState = {
  spotId?: string;
};

export default BookingDetailPage;
