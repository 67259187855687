import styled from 'styled-components';

export const CardContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  width: 100%;
  max-width: 648px;
  position: relative;
`;

export const CardImage = styled.div<CardImageProps>`
  background: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: cover;
  background-position: center;
  height: 240px;
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px;
  justify-content: flex-end;
`;

type CardImageProps = {
  imageUrl: string;
};

export const CardContent = styled.div`
  padding: 24px;
`;

export const CardHeader = styled.div`
  padding: 0 0 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.background};
  margin-bottom: 24px;
  justify-content: space-between;
  align-items: center;
`;

export const CardMetaContainer = styled.div`
  margin-bottom: 16px;
`;

export const LabelContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;
