import styled from 'styled-components';

import { media } from 'styles/utils';

export const PageContainer = styled.div`
  background: ${({ theme }) => theme.colors.gray.background};
`;

export const ContentContainer = styled.div`
  max-width: 1178px;
  padding: 40px 24px;
  margin: 0 auto;

  ${media.desktop`
    display: grid;
    grid-template-columns: 1fr 0.75fr;
    grid-gap: 24px;
  `}
`;

export const PreviousButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  & svg {
    margin-right: 10px;
  }
`;

export const Content = styled.div`
  margin-bottom: 32px;

  ${media.desktop`
    padding-right: 24px;
    flex: 1;
  `}
`;
