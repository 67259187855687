import * as React from 'react';

import { Logo } from 'common/general';

import { HeaderContainer, LogoWrapper } from './styled';

export const BookingDetailHeader = () => {
  return (
    <HeaderContainer>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
    </HeaderContainer>
  );
};
