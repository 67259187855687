import styled from 'styled-components';

import { media } from 'styles/utils';
import InstructorIcon from 'vectors/instructorIcon.svg';
import LocationMarker from 'vectors/locationMarker.svg';

export const LocationMarkerSvg = styled(LocationMarker)`
  width: 13.33px;
  height: 13.33px;

  ${media.desktop`
    width: 16px;
    height: 16px;
  `}
`;

export const InstructorSvg = styled(InstructorIcon)`
  width: 13.33px;
  height: 13.33px;

  ${media.desktop`
    width: 16px;
    height: 16px;
  `}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3.33px;
  align-items: center;

  ${media.desktop`
    gap: 6px;
  `}
`;

export const MetaContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  color: ${({ theme }) => theme.colors.black};
  flex: 1;
  padding-left: 8px;

  ${media.desktop`
    gap: 8px;
  `}
`;

