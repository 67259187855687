import * as React from 'react';
import { graphql } from 'gatsby';
import { Router } from '@reach/router';

import { Template } from 'common/layout';
import BookingDetailPage from 'modules/BookingDetailPage';

const Class = () => {
  return (
    <Template>
      <Router basepath="/:lang">
        <BookingDetailPage path="/class/:id/*" />
      </Router>
    </Template>
  );
};

export default Class;

export const query = graphql`
  query Class($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          language
          data
        }
      }
    }
  }
`;
