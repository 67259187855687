import * as i from 'types';
import { createSelector } from 'reselect';

import { selectActiveBookings } from 'selectors/booking';

export const selectClassDetail = (state: i.ReduxState) => state.classesDetail.data;

export const selectClass = createSelector(
  selectClassDetail,
  selectActiveBookings,
  (classDetail, bookings) => classDetail && ({
    ...classDetail,
    isBooked: bookings?.some((booking) => booking.classId === classDetail?.id),
    isWaitlisted: bookings?.some((booking) =>
      booking.classId === classDetail?.id && booking.status === 'Waitlisted'),
    isWaitlistExpired: bookings?.some((booking) =>
      booking.classId === classDetail?.id && booking.status === 'Waitlist Expired'),
  }),
);
