import * as i from 'types';
import React from 'react';

import { SpotItem } from '../SpotItem';
import { Instructor } from '../Instructor';
import { PlaceholderContainer, Container } from './styled';

export const SpotMap: React.FC<SpotMapProps> = ({
  spots, layout, onSelectSpot, selectedSpot,
}) => (
  <PlaceholderContainer>
    <Container>
      {layout && spots?.map((spot: i.Spot) => (
        <SpotItem
          key={spot.id}
          spotItem={spot}
          mapItem={layout?.spots?.find((mapSpot: i.StudioLayoutSpot) => mapSpot.id === spot.id)}
          onSelectSpot={onSelectSpot}
          selectedSpot={selectedSpot}
        />
      ))}
      {layout.instructor && <Instructor position={layout.instructor.position} />}
    </Container>
  </PlaceholderContainer>
);

type SpotMapProps = {
  spots?: i.Spot[];
  instructor?: i.Instructor;
  layout: i.StudioLayout;
  selectedSpot?: string;
  onSelectSpot: (id: string) => void;
};
