import styled from 'styled-components';

export const BookingSuccessContainer = styled.div`
  margin-top: 16px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
