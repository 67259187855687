import * as React from 'react';

import { Text } from 'common/typography';
import Duration from 'vectors/duration.svg';

import { Row, LocationMarkerSvg, InstructorSvg, MetaContentContainer } from './styled';

export const BookingMeta: React.FC<BookingMetaProps> = ({ site, instructorName, classLength }) => {
  return (
    <MetaContentContainer>
      <Row>
        <LocationMarkerSvg />
        <Text $size={12}>
          {site}
        </Text>
      </Row>
      <Row>
        <InstructorSvg />
        <Text $size={12}>
          {instructorName}
        </Text>
      </Row>
      {classLength && (
        <Row>
          <Duration />
          <Text $size={12}>
            {classLength}
          </Text>
        </Row>
      )}
    </MetaContentContainer>
  );
};

type BookingMetaProps = {
  site?: string;
  instructorName?: string;
  classLength?: string;
};
