import styled from 'styled-components';

import { media } from 'styles/utils';

export const HeaderContainer = styled.header`
  height: 96px;
  background: ${({ theme }) => theme.colors.primary};

  ${media.desktop`
    height: 112px;
  `}
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  padding-top: 36px;

  ${media.desktop`
    padding-top: 30px;
  `}
`;
