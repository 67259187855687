import * as i from 'types';
import * as React from 'react';

import { BookingLabel, BookingMeta } from 'modules/booking';
import {
  formatTime, formatWrittenDateYear, generateBookingLabel, generateClassesLabel,
} from 'services';

import {
  BookingCardContainer,
  CardWrapper,
  TimeText,
  ClassTypeText,
  VerticalLine,
  ContentContainer,
  LabelContainer,
  ExtendedLink,
} from './styled';

export const BookingCard: React.FC<BookingCardProps> = ({ item }) => {
  const isSerie = 'attendanceId' in item;
  const label = isSerie ? generateClassesLabel(item) : generateBookingLabel(item);
  const hasEvent = isSerie ? false : Boolean(item.event);
  const title = isSerie ? item.classType : formatTime(item.classDate);
  const subTitle = isSerie ? `${formatWrittenDateYear(item.classDate)}, ${formatTime(item.classDate)}` : item.classType;

  return (
    <ExtendedLink to={`/class/${isSerie ? item.classId : item.id}`}>
      <BookingCardContainer event={hasEvent}>
        {label && (
          <LabelContainer>
            <BookingLabel variant={label} />
          </LabelContainer>
        )}
        <CardWrapper>
          <ContentContainer>
            <TimeText>{title}</TimeText>
            <ClassTypeText>{subTitle}</ClassTypeText>
          </ContentContainer>
          <VerticalLine />
          <BookingMeta
            site={item?.siteName}
            instructorName={item?.instructorName}
          />
        </CardWrapper>
      </BookingCardContainer>
    </ExtendedLink>
  );
};

type BookingCardProps = {
  item: i.Class | i.Serie;
};
