import * as i from 'types';
import * as React from 'react';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import { useDispatch, useSelector, useAuthenticationUser } from 'services/hooks';
import { getClassSpots } from 'ducks/classes/detail';
import { Heading } from 'common/typography';
import { Button } from 'common/interaction';

import { SpotMap } from '../SpotMap';

export const BookingSpots: React.FC<BookingSpotsProps> = ({
  id, selectedSpot, setSelectedSpot, submitBooking, selectedSpotMap,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { navigate } = useI18next();
  const { authenticated, determined } = useAuthenticationUser();
  const spots = useSelector((state) => state.classesDetail.spots);
  const series = useSelector((state) => state.classesDetail.series);
  const activeSpot = spots?.find((spot) => spot.id === selectedSpot);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [studioLayout, setStudioLayout] = React.useState({});

  React.useEffect(() => {
    if (!authenticated && determined) {
      navigate('/booking');
      return;
    }

    if (id) {
      dispatch(getClassSpots(id)).then((spots) => {
        if (selectedSpot) return;
        const firstSpot = spots?.find((spot) => spot.status === 'Available');
        setSelectedSpot(firstSpot?.id || '');
      });
    }
  }, []);

  React.useEffect(() => {
    // the url of the spotmap json file needs to be fetched seperatly.
    if (selectedSpotMap) {
      setIsLoading(true);

      fetch(selectedSpotMap)
        .then((res) => res.json())
        .then((res) => {
          setIsLoading(false);
          setStudioLayout(res);
        });
    }
  }, [selectedSpotMap]);

  const nextPage = () => {
    if (series?.length === 1) return submitBooking();
    navigate(`/class/${id}/series`, { state: { spotId: selectedSpot } });
  };

  return (
    <>
      <Heading as="h2" variant="black">{t('pick a spot')}</Heading>
      <>
        {!isLoading && studioLayout && (spots?.length || 0) > 0 && (
          <SpotMap
            layout={studioLayout as i.StudioLayout}
            spots={spots}
            onSelectSpot={setSelectedSpot}
            selectedSpot={selectedSpot}
          />
        )}
      </>
      <Button variant="CTA" size="fullWidth" onClick={nextPage} isLoading={isLoading}>
        {t('pick spot')} {activeSpot?.label}
      </Button>
    </>
  );
};

type BookingSpotsProps = {
  id?: string;
  path: string;
  selectedSpot?: string;
  setSelectedSpot: (id: string) => void;
  submitBooking: (isWaitList?: boolean) => void;
  selectedSpotMap: string;
};
