import * as i from 'types';
import React from 'react';

import { Container, Title } from './styled';

export const SpotItem: React.FC<SpotItemProps> = ({
  spotItem, mapItem, onSelectSpot, selectedSpot,
}) => {
  const disabled = ['Unavailable', 'Enrolled'].includes(spotItem.status);

  const handleSelection = () => {
    if (!disabled) onSelectSpot(spotItem.id);
  };

  if (!mapItem || !spotItem) return null;

  return (
    <Container
      onClick={handleSelection}
      $status={spotItem.status}
      $selected={selectedSpot === spotItem.id}
      $position={mapItem.position}
      $disabled={disabled}
      $variant={mapItem.variant}
    >
      <Title
        $status={spotItem.status}
        $selected={selectedSpot === spotItem.id}
      >
        {spotItem.label}
      </Title>
    </Container>
  );
};

type SpotItemProps = {
  spotItem: i.Spot;
  mapItem?: i.StudioLayoutSpot;
  onSelectSpot: (id: string) => void;
  selectedSpot?: string;
};
