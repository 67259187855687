import styled from 'styled-components';

export const PlaceholderContainer = styled.div`
  flex: 1;
  width: 100%;
  overflow: auto;
`;

export const Container = styled.div`
  position: relative;
  width: 320px;
  height: 600px;
`;
