import * as i from 'types';
import * as React from 'react';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import { useAuthenticationUser, useDispatch, useSelector } from 'services/hooks';
import { isSameOrAfterToday } from 'services';
import { cancelBooking } from 'ducks/booking';
import { getUserClasses } from 'ducks/me';
import { Button } from 'common/interaction';
import { ConfirmModal } from 'common/layout';
import { selectActiveBooking } from 'selectors/booking';

import { BookingCtaDetails } from './components';

export const BookingCTA: React.FC<BookingCTAProps> = ({ classDetail, submitBooking, isLoading }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { navigate } = useI18next();
  const classSeries = useSelector((state) => state.classesDetail.series);
  const booking = useSelector((state) => selectActiveBooking(state, classDetail.id));
  const [isModalOpen, openModal] = React.useState(false);
  const { authenticated } = useAuthenticationUser();

  const nextPage = () => {
    if (classDetail.hasLayout) {
      navigate(`/class/${classDetail.id}/spots`);
    } else if ((classSeries?.length || 0) > 1) {
      navigate(`/class/${classDetail.id}/series`);
    }
    // If the class is full and waitlist is full (so class is sold out) then
    // redirect back to the booking overview page
    else if (classDetail?.full && classDetail?.waitlistFull) {
      navigate('/booking');
    } else {
      submitBooking();
    }
  };

  const handleCloseModal = () => {
    openModal(false);
  };

  const handleOpenModal = () => {
    openModal(true);
  };

  const confirmCancel = () => {
    if (classDetail?.id) {
      dispatch(cancelBooking({
        classId: classDetail?.id,
        attendanceId: booking?.attendanceId,
        lateCancel: !booking?.cancellable,
      })).then(() => {
        handleCloseModal();
        dispatch(getUserClasses());
      });
    }
  };

  const waitlist = () => {
    if ((classSeries?.length || 0) > 1) {
      navigate(`/class/${classDetail.id}/series?waitList=true`);
    } else {
      submitBooking(true);
    }
    handleCloseModal();
  };

  // Show login/register buttons if user is not logged in
  if (!authenticated) {
    const redirectUrl = `/class/${classDetail.id}`;

    return (
      <BookingCtaDetails
        title={t('you must be logged in to book this class')}
        subtitle={t('you must be logged in to book a class')}
        topButton={
          <Button
            variant="CTA"
            size="fullWidth"
            onClick={() => navigate('/register', { state: { redirectUrl } })}
          >
            {t('create account')}
          </Button>
        }
        bottomButton={
          <Button
            size="fullWidth"
            onClick={() => navigate('/login', { state: { redirectUrl } })}
          >
            {t('login')}
          </Button>
        }
      />
    );
  }

  // If the user has booked this class and they are not on the waitlist then
  // show a button for canceling the booking.
  // If the booking is already past, hide cancel button.
  if (booking && !classDetail?.isWaitlisted) {
    return (
      <BookingCtaDetails
        title={t('you have booked this workout')}
        subtitle={t('you are ready to workout')}
        topButton={isSameOrAfterToday(booking.classDate) &&
          <Button variant="warning" size="fullWidth" onClick={handleOpenModal}>
            {t('cancel booking')}
          </Button>
        }
        modal={
          <ConfirmModal
            title={t('are you sure you want to cancel this class')}
            description={booking.cancellable
              ? `${t('you are about to cancel your class, are you sure')}`
              : `${t('you are about to cancel your class. a late fee shall be charged on your account')}`}
            isOpen={isModalOpen}
            closeModal={handleCloseModal}
            confirmAction={confirmCancel}
          />
        }
      />
    );
  }

  // If a user is on the waitlist for this class then show a button
  // for removing the user from waitlist
  if (booking && classDetail?.isWaitlisted) {
    return (
      <BookingCtaDetails
        title={t('you are on the waitlist')}
        subtitle={t('you are now on the waiting list. as soon as a spot becomes available, we will register you for the class')}
        topButton={
          <Button variant="warning" size="fullWidth" onClick={handleOpenModal}>
            {t('remove from waitlist')}
          </Button>
        }
        modal={
          <ConfirmModal
            title={t('are you sure')}
            description={t('you will be removed from the waitlist')}
            isOpen={isModalOpen}
            closeModal={handleCloseModal}
            confirmAction={confirmCancel}
          />
        }
      />
    );
  }

  // If user hasn't booked the class and doesn't have any credits to book the class then show
  // button for purchasing credits
  if (classSeries?.length === 0) {
    return (
      <BookingCtaDetails
        title={t('you have no class credits')}
        subtitle={t('buy class credits so you can book a workout')}
        topButton={
          <Button variant="CTA" size="fullWidth" onClick={() => navigate('/pricing')}>
            {t('buy class credits')}
          </Button>
        }
      />
    );
  }

  // If user hasn't booked the class and the class if fully booked but there is waitlist available
  // then show button to add user to waitlist
  if (classDetail?.full && !classDetail?.waitlistFull) {
    return (
      <BookingCtaDetails
        title={t('shall we put you on the waitlist')}
        subtitle={t('confirm that you want to be added to the waitlist')}
        topButton={
          <Button variant="CTA" size="fullWidth" onClick={handleOpenModal}>
            {t('put me on the waitlist')}
          </Button>
        }
        modal={
          <ConfirmModal
            title={t('you will be put on the waitlist')}
            description={t('as soon as a spot becomes available, we will register you for the class')}
            isOpen={isModalOpen}
            closeModal={handleCloseModal}
            confirmAction={waitlist}
          />
        }
      />
    );
  }

  // If user has not booked the class and the class is full and the waitlist is also full
  // then show a button that redirects user back to class overview
  if (classDetail?.full && classDetail.waitlistFull) {
    return (
      <BookingCtaDetails
        title={t('you are ready to book this class')}
        subtitle={t('book this class by picking a spot')}
        topButton={
          <Button variant="CTA" size="fullWidth" onClick={nextPage} isLoading={isLoading}>
            {t('book another class')}
          </Button>
        }
      />
    );
  }

  // If user hasn't booked the class and the class is available show a button to
  // book the the class
  return (
    <BookingCtaDetails
      title={t('you are ready to book this class')}
      subtitle={t('book this class by picking a spot')}
      topButton={
        <Button variant="CTA" size="fullWidth" onClick={nextPage} isLoading={isLoading}>
          {t('pick a spot')}
        </Button>
      }
    />
  );
};

type BookingCTAProps = {
  id?: string;
  path: string;
  submitBooking: (isWaitList?: boolean) => void;
  classDetail: i.Class;
  isLoading?: boolean;
};
