import * as React from 'react';

import { Heading, Text } from 'common/typography';

import { ButtonContainer } from './styled';

export const BookingCtaDetails = ({
  title, subtitle, topButton, bottomButton, modal,
}: BookingCtaDetailsProps) => {
  return (
    <>
      <Heading as="h2" variant="black">{title}</Heading>
      <Text $margin="0 0 32px">{subtitle}</Text>
      <ButtonContainer>
        {topButton && topButton}
        {bottomButton}
      </ButtonContainer>
      {modal}
    </>
  );
};

type BookingCtaDetailsProps = {
  title: string;
  subtitle: string;
  topButton: React.ReactNode;
  bottomButton?: React.ReactNode;
  modal?: React.ReactNode;
};
