import * as i from 'types';
import * as React from 'react';

import { BookingLabelWrapper, ExtendedText } from './styled';

export const BookingLabel: React.FC<BookingLabelProps> = ({ variant }) => {
  return (
    <BookingLabelWrapper backgroundColor={variant.backgroundColor}>
      <ExtendedText textColor={variant.textColor}>{variant.text}</ExtendedText>
    </BookingLabelWrapper>
  );
};

type BookingLabelProps = {
  variant: {
    text: string;
    backgroundColor: i.LabelColors;
    textColor?: i.TextColors;
  };
};
